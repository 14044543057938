.locations {
  /* background-color: var(--darkblue-lighter-color); */
  min-height: 495px;
  width: 100%;
}


.leaflet-popup-content-wrapper, .leaflet-container a {
  color: var(--secondary-color);
}


@media (min-width: 576px) {
  .copyshop-info-modal {
      max-width: 600px;
  }
}
