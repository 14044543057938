:root {
  --soMe_green: #79F558;
  --soMe_black: #000;
  --soMe_gray: #EDEDED;

  --soMe_radius: 15px;
}

.soMeCampaign {
  color: var(--soMe_black);
}

.soMeCampaign .title {
  font-family: 'Roster Bold';
  font-size: 2rem;
}

.soMeCampaign .subTitle {
  font-family: 'Roster';
}

.soMeCampaign .highlight {
  color: var(--soMe_green);
  white-space: nowrap;
}

.soMeCampaign .form-control, .soMeCampaign .input-group-text {
  background-color: var(--soMe_gray);
  border-color: var(--soMe_gray);
  color: var(--soMe_black);
}

.soMeCampaign .form-control {
  border-radius: var(--soMe_radius);
}

.soMeCampaign .input-group-text {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--soMe_radius);
  border-bottom-right-radius: var(--soMe_radius);
}

.soMeCampaign a:hover {
  font-weight: bold;
}

.soMeCampaign .policy-text a {
  color: var(--soMe_black);
}

.soMeCampaign .policy-text a:hover {
  color: var(--soMe_black);
}

.soMeCampaign .btn {
  background-color: var(--soMe_green);
  border-color: var(--soMe_green);
  color: var(--soMe_black);
  font-weight: bold;
  border-radius: var(--soMe_radius);
}

.soMeCampaign .btn:hover {
  background-color: var(--soMe_black);
  border-color: var(--soMe_black);
  color: var(--white);
}

.soMeCampaign .btn-secondary {
  background-color: var(--soMe_gray);
  border-color: var(--soMe_gray);
  border-radius: var(--soMe_radius);
  min-width: 42px;
}

.soMeCampaign .btn-secondary:hover {
  background-color: var(--soMe_green);
  border-color: var(--soMe_green);
}

.soMeCampaign .dropdown .dropdown-menu {
  min-width: auto;
}

.soMeCampaign .dropdown .btn, .soMeCampaign .dropdown .btn:hover {
  background-color: transparent;
  border-color: transparent;
  color: var(--soMe_black);
}

.soMeCampaign .dropdown .dropdown-item.active, .soMeCampaign .dropdown .dropdown-item.active:focus {
  background-color: var(--soMe_green);
  border-color: var(--soMe_green);
}

.soMeCampaign .dropdown .dropdown-item:hover:not(.active) {
  background-color: var(--soMe_gray);
  border-color: var(--soMe_gray);
}

@media (max-width: 767px) { /* Below MD */

  .soMeCampaign {
    background: none !important;
  }

  .soMeCampaign .title {
    font-size: 1.8rem;
  }

  .soMeCampaign .subTitle {
    font-size: 1rem;
    line-height: 200%;
  }
}

@media (min-width: 768px) { /* MD */

  .soMeCampaign {
    background-size: 45%;
  }
}

@media (min-width: 992px) { /* LG */

  .soMeCampaign {
    background-size: 40%;
  }
}

@media (min-width: 1200px) { /* XL */

  .soMeCampaign {
    background-size: 35%;
  }
}

