.copyOrderIdButton {
  font-size: .8rem;
}

.copyOrderIdButton:hover {
  font-weight: bold;
}

.accordion.order-list {

  .card-body {
    padding: 1rem;
  }

}

@media (max-width: 992px) { /* Below LG */
  .copyOrderIdButton {
    font-size: 1rem;
  }
}