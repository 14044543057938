:root {

  /* White */
  --white: #FFF;

  /* Green */
  --green-dark-color: #67912f;
  --green-color: #8bc53f;
  --green-light-color: #c5e29f;
  --green-lighter-color: #edf6e2;

  /* Dark blue */
  --darkblue-color: #516b85;
  --darkblue-light-color: #a7b4c1;
  --darkblue-lighter-color: #e4e8ec;
  --darkblue-lightest-color: #e4e8ec85;

  /* Red */
  --red-dark-color: #c53f3f;
  --red-color: #ff4f4f;
  --red-lighter-color: #ffdfdf;

  /* Tangerine */
  --tangerine-color: #EFA48B;
  --tangerine-dark-color: #EB8E6F;
  --tangerine-light-color: #F5C7B7;

  --facebook-blue: #4267b2;

  /* New palette of colors */
  --primary-color: #8bc53f;
  --primary-dark-color: #669047;
  --primary-light-color: #E1EDCF;

  --secondary-color: #2E4052;
  --secondary-dark-color: #53754A;
  --secondary-light-color: #E1EDCF;

  --accent-color: #EFA48B;
  --accent-dark-color: #C89080;
  --accent-light-color: #E4DEC1;

  --danger-color: #C53F48;
  --danger-dark-color: #933F4B;
  --danger-light-color: #ffcdd2;
  
  --ai-color: #ff33e9;
  --ai-dark-color: #b300a0;
  --ai-light-color: #ff66ef;

  --ai-secondary-color: #00b4ff;
  --ai-secondary-dark-color: #0090cc;
  --ai-secondary-light-color: #33c3ff;
}