
  .MuiDrawer-paper {
    position: absolute !important;
    width: 240px;
    overflow-y: initial !important;
  }

  div:has(.MuiDrawer-paper) {
    position: relative;
  }   
  
