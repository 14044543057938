@keyframes fadeSlideIn {
    0% {
        opacity: 0;
        -webkit-transform: translateY(80%);
        transform: translateY(80%)
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none
    }
}
html, body {
    height: 100%;
}
body {
    background-color: #fff;
}

#root {
    height: 100%;
}

.app {
    display: flex;
    flex: 1;
}

.sidebar {
    background: #fff url(../images/bookstack_left.png) no-repeat 100% 100%;
    background-size: cover;
}

.sidebar-text-container {
    padding: 60px;
    max-width: 450px;
}

.sidebar-text-container h1 {
    font-size: 2.5rem;
    opacity: 0;
    animation: fadeSlideIn .5s .7s forwards;
}

.sidebar-text-container h2 {
    font-size: 1.5rem;
    opacity: 0;
    animation: fadeSlideIn .3s .9s forwards;
}

main {
    display: flex;
    width: 100%;
    overflow-y: auto;
}

@media (min-width: 768px) {
    .sidebar {
        width: 40%;
    }

    main {
        width: 60%;
    }
}