footer {
  z-index: 99999 !important;
  background-color: var(--secondary-color);
  color: var(--white);
}

footer a.zerocopy-link {
  color: var(--white) !important;
  font-weight: normal !important;
}

footer a.zerocopy-link.active {
  color: var(--secondary-color) !important;
  background-color: var(--white) !important;
}

.footer-sm-link, .footer-sm-link:link, .footer-sm-link:hover, .footer-sm-link:active, .footer-sm-link:visited {
  color: var(--white);
  font-weight: 600;
  text-decoration: none;
}

footer .list-inline:not(.language-selector) .list-inline-item:not(:last-child) {
  margin-right: 1rem;
}

footer .list-inline.social-media .list-inline-item:not(:last-child) {
  margin-right: .35rem;
}

footer .list-inline.language-selector .list-inline-item:not(:last-child) {
  margin-right: .2rem;
}

footer .language-selector a.zerocopy-link {
  padding: 0.2rem;
}
