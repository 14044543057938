.Select--multi .Select-value {
  border-color: #8CC349;
  background-color: #c6e1a2;
  color: #8CC349;
}


.Select--multi .Select-value .Select-value-icon:hover {
  color: #8CC349;
  border-color: #8CC349;
}
