.language-selector a.zerocopy-link {
  padding: 0.5rem;
  border: 2px solid transparent;
  background-color: transparent;
}

.language-selector a.zerocopy-link,
.language-selector a.zerocopy-link:link,
.language-selector a.zerocopy-link:hover,
.language-selector a.zerocopy-link:active,
.language-selector a.zerocopy-link:visited {
  color: var(--secondary-color);
  font-weight: 600;
  text-decoration: none;
  border-radius: 4px;
}

.language-selector a.zerocopy-link.active {
  border: 2px solid var(--secondary-color);
}