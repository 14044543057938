.error-page-wrapper {
  min-width: 300px;
}
.error-page-logo {
  width: 100%;
}

.error-page {
  max-width: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
.error-page h1 {
  font-size: 4rem;
  line-height: 1em;
  background: linear-gradient(var(--green-dark-color), var(--green-light-color));
  background-clip: text;
  color: transparent;
}

.error-page h4 {
  text-transform: uppercase;
  font-size: 1.5rem;
}

.error-page p {
  font-size: 1rem;
}

@media (min-width: 576px) {

  .error-page-logo {
    max-width: 550px;
  }

  .error-page h1 {
    font-size: 5rem;
  }

  .error-page h4 {
    font-size: 2rem;
  }

  .error-page p {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) {

  .error-page-logo {
    max-width: 600px;
  }

  .error-page h1 {
    font-size: 6rem;
  }
}

@media (min-width: 992px) {
  .error-page h1 {
    font-size: 8rem;
  }
}