.circle-error {
  color: #ff4f4f;
}

.circle-in-progress {
  color: #a7b4c1;
}

.circle-ready, .circle-printed  {
  color: #8bc53f;
}
