.doc-name {
  max-width: 100%;
}

.doc-icon {
  font-size: 1.6rem;
  vertical-align: middle;
  color: var(--gray);
  cursor: pointer;
}

.doc-icon.eye-icon:hover {
  color: var(--primary-color);
  font-weight: bold;
}

.doc-icon.trash-icon:hover, .doc-icon.report-icon:hover {
  color: var(--danger-color);
  font-weight: bold;
}

.doc-icon.disabled {
  cursor: not-allowed !important;
}

@media (max-width: 767) {
  .doc-name {
    max-width: 275px;
  }

  .doc-icon {
    font-size: 1.5rem;
  }
  
  .sm-screen {
    display: flex;
  }
}

@media (min-width: 768px) {
  .doc-name {
    max-width: 300px;
  }

  .doc-icon {
    font-size: 1.3rem;
  }

  .sm-screen {
    display: none;
  }
  
}

@media (min-width: 992px) {
  .doc-name {
    max-width: 325px;
  }

  .sm-screen {
    display: none;
  }
}

@media (min-width: 1200px) {
  .doc-name {
    max-width: 400px;
  }

  .sm-screen {
    display: none;
  }
}

@media (min-width: 1500px) {
  .doc-name {
    max-width: 500px;
  }

  .sm-screen {
    display: none;
  }
}

@media (min-width: 1800px) {
  .doc-name {
    max-width: 650px;
  }

  .sm-screen {
    display: none;
  }
}
