.create-account-page form label {
  display: none;
}

.create-account-page .policy-text a, .create-account-page .policy-text a:hover {
  text-decoration: underline;
  color: var(--secondary-color);
}

.create-account-page .policy-text a:hover {
  font-weight: bold;
}

.create-account-page form .input-group input {
  border-right-width: 0;
}
.create-account-page form .input-group .input-group-text {
  background-color: var(--white);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.create-account-page .fa-envelope {
  font-size: 1.5rem;
}