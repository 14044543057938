.my-profile .section-title {
    background-color: var(--darkblue-lighter-color);
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0.5rem 0.25rem 0.5rem;
}

.my-profile .section-title .section-title-text {
    margin-bottom: 0;
}

.my-profile .section-content {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
