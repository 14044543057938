.dv-star-rating-star {
  font-size: 2rem;
}

.rating-likely {
  font-size: 0.7rem;
  color: #FFF;
}

@media (max-width: 471px) {
  .dv-star-rating-star {
    font-size: 1.5rem;
  }
}

@media (max-width: 368px) {
  .dv-star-rating-star {
    font-size: 1rem;
  }
}
