.leaderboard-header a {
    color: var(--secondary-color);
}

.leaderboard-header div.active a {
    font-weight: bold;
}

.leaderboard .overflow-auto {
  max-height: 250px;
}

.leaderboard .overflow-auto .leaderboard-item {
  margin-right: .5rem;
}

.leaderboard-item {
    padding: 0 20px 5px 20px;
}

.leaderboard-first, .leaderboard-second, .leaderboard-third {
    margin-bottom: 1rem;
    border-radius: 50px;
    padding: 10px 20px 10px 20px;
}

.leaderboard-first {
    background: rgba(252,231,51,1);
    background: linear-gradient(to right, rgba(252,231,51,1) 0%, rgba(230,183,37,1) 100%);
}

.leaderboard-second {
    background: rgba(228,228,228,1);
    background: linear-gradient(to right, rgba(228,228,228,1) 0%, rgba(203,203,203,1) 100%);
}

.leaderboard-third { 
    background: rgba(245,201,155,1);
    background: linear-gradient(to right, rgba(245,201,155,1) 0%, rgba(216,135,96,1) 100%);
}

.leaderboard .info-icon {
  font-size: 1.3rem;
}