.LocationFilter .filter-item {
  cursor: pointer;
  border: none;
  background-color: transparent;
  padding: 4px;
}

.LocationFilter .filter-item.active {
  cursor: default;
  border-radius: 4px;
  background-color: #516b85;
  color: #FFF;
}

.LocationFilter ::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}

@media (max-width: 767.98px) {
  .LocationFilter {
    width: 100%!important;
  }
}
@media (min-width: 767.98px) {
  .LocationFilter {
    width: 50%!important;
  }
}
