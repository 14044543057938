.doc-preview {
  min-height: 600px;
  width: 98%;
}


@media (max-width: 600px) {

  .doc-preview {
    min-height: 85vh !important;
  }
}

@media (max-width: 991.98px) {

  .doc-preview {
    min-height: 475px;
  }
}

/* TODO -- work on animations: https://tympanus.net/Development/ModalWindowEffects/ */
.fade-scale {
  transform: scale(0);
  opacity: 0;
  -webkit-transition: all .25s linear;
  -o-transition: all .25s linear;
  transition: all .25s linear;
}

.fade-scale.in {
  opacity: 1;
  transform: scale(1);
}
