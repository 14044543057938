ul.delivery-docs {
  padding-inline-start: 1rem;
}

@media (max-width: 576px) { /* XS */
  .delivery-doc-name {
    width: 100%;
  }
}

@media (min-width: 576px) { /* SM */
  .delivery-doc-name {
    width: 100%;
  }
}

@media (min-width: 768px) { /* MD */
  .delivery-doc-name {
    width: 30rem;
  }
}

@media (min-width: 992px) { /* LG */
  .delivery-doc-name {
    width: 40rem;
  }
}

@media (min-width: 1200px) { /* XL */
  .delivery-doc-name {
    width: 50rem;
  }
}
