@media (max-width: 576px) { /* XS */
  .shared-doc-name {
    max-width: 200px;
  }
}

@media (min-width: 576px) { /* SM */
  .shared-doc-name {
    max-width: 300px;
  }
}

@media (min-width: 768px) { /* MD */
  .shared-doc-name {
    max-width: 700px;
  }
}

@media (min-width: 992px) { /* LG */
  .shared-doc-name {
    max-width: 700px;
  }
}

@media (min-width: 1200px) { /* XL */
  .shared-doc-name {
    max-width: 820px;
  }
}
