.billboard {
  background-color: #FFFFFF;
  max-height: 250px;
}

.billboard-image-container {
  max-width: 950px;
  max-height: 250px;
}

.billboard-image, .billboard-adsense {
  max-width: 100%;
  height: auto;
  text-align: center;
}

@media (min-width: 767.98px) {

  .billboard-image, .billboard-adsense  {
    height: 250px;
  }

}
