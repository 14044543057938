.Dropzone {
  border: dashed 2px var(--darkblue-color);
  border-radius: 10px;
  background: var(--darkblue-lightest-color);
}
.Dropzone:hover .btn { 
  background: var(--accent-dark-color);
  border-color: var(--accent-dark-color);
}

.upload-container {
  /* border: solid 2px var(--darkblue-color); */
  border-radius: 10px;
}
