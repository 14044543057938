.competition-leaderboard .leaderboard-item {
  border-radius: 23px;
  background-color: var(--secondary-color);
  color: var(--white);
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.competition-leaderboard .overflow-auto {
  max-height: 250px;
}
.competition-leaderboard .overflow-auto .leaderboard-item {
  margin-right: .5rem;
}

.competition-leaderboard .leaderboard-item:not(:last-child) {
  margin-bottom: .75rem;
}

.competition-leaderboard .leaderboard-item .position {
  min-width: 25px;
}

.competition-leaderboard .leaderboard-user-item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.competition-leaderboard .leaderboard-item .text, .competition-leaderboard .leaderboard-user-item .text {
  word-break: break-all;
}

.competition-leaderboard .leaderboard-item .value, .competition-leaderboard .leaderboard-user-item .value {
  margin-left: 0.75rem;
}

.competition-leaderboard .leaderboard-user-item .text {
  margin-left: 0.5rem;
}

.competition-leaderboard .leaderboard-user-item .value {
  margin-right: 0.75rem;
}

.competition-leaderboard .card-footer {
  background-color: transparent !important;
}