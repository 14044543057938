.login-page form label {
  display: none;
}
.login-page form .input-group input {
  border-right-width: 0;
}
.login-page form .input-group .input-group-text {
  background-color: var(--white);
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.login-page .fa-envelope {
  font-size: 1.5rem;
}