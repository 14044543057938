#addressFormModal {
  max-width: 700px;
}

@media (max-width: 767.98px) {

  #addressFormModal {
    max-width: 100%;
  }
}
