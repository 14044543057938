.table-stats thead {
  background-color: var(--darkblue-lighter-color);
}

.table-stats tfoot {
  border-top: 1px solid var(--darkblue-lighter-color);
}

.table-stats tbody td, .table-stats thead th {
  border: none;
}

.table-stats thead th {
  font-weight: normal;
}

.vertical-centered-text {
  display: flex;
  align-items: center;
}


@media (max-width: 576px) { /* XS */
  .stats-icon {
    font-size: 3rem;
  }
  .stats-data {
    width: 100%;
  }
}


@media (min-width: 576px) { /* SM */
  .stats-icon {
    font-size: 3rem;
  }
  .stats-data {
    width: 300px;
  }
}

@media (max-width: 768px) { /* MD */
  .stats-icon {
    font-size: 3rem;
  }
}

@media (min-width: 992px) { /* LG */
  .stats-icon {
    font-size: 3rem;
  }
}

@media (min-width: 1200px) { /* XL */
  .stats-icon {
    font-size: 3.5rem;
  }
}
