.bg-zerocopy {
  background-color: #8CC449 !important;
}

.bg-zerocopy-gradient {
  background: rgb(140,196,73);
  background: linear-gradient(90deg, rgba(140,196,73,1) 0%, rgba(103,145,47,1) 100%);
}

/* Navbar */
.navbar-light .navbar-nav .nav-item.show {
  background-color: #ADD47B;
  border-radius: 4px;
}

.navbar-light .navbar-nav .nav-item.accent {
  background-color: #ffffff;
  border-radius: 4px;
}

.navbar-light .navbar-toggler, .navbar-light .navbar-toggler:focus {
  color: #FFF;
  border-color: transparent;
  outline: 0;
}

.navbar-light .navbar-nav .nav-item.accent .nav-link {
  color: #8bc53f;
}

.navbar-light .navbar-nav .nav-link, .navbar-light .navbar-nav .nav-link:link, .navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:visited, .navbar-light .navbar-nav .nav-link:active {
  color: #FFF;
}

.new-users-credits {
  font-size: 1.5rem;
  padding: 0;
}

@media (max-width: 767.98px) {

  .navbar-light .navbar-nav .nav-item {
    padding-left: .3rem;
    padding-right: .3rem;
  }

  .nav-link {
    padding: .2rem 1rem
  }

}

@media (max-width: 500px) {

  .new-users-credits {
    font-size: 1.2rem;
  }

}

@media (max-width: 425px) {

  .new-users-header {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  .new-users-header .navbar-toggler {
    padding-right: .5rem !important;
    padding-left: .5rem !important;
  }

  /* 75% of current width */
  .new-users-logo img {
    height: 22.5px;
    width: 133.5px;
  }

}

@media (max-width: 378px) {

  /* 65% of current width */
  .new-users-logo img {
    height: 18px;
    width: 106.8px;
  }

  .new-users-credits {
    font-size: 1rem;
  }

}

@media (max-width: 332px) {

  .new-users-logo {
    display: none !important;
  }

}
