@media (max-width: 773px) {
  .copyshop-img {
      width: 100%;
      height: auto;
  }
}

@media (min-width: 774px) {
  .copyshop-img {
      width: 15rem;
  }
}