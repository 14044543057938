.globalMessage {
  width: 100%;
  background-color: var(--darkblue-lighter-color);
  min-height: 60px;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
}

.globalMessage a {
  color: #FFF;
  text-decoration: underline;
}

.globalMessage.primary {
  background-color: #8bc53fb9;
  color: #FFF;
}

.globalMessage.secondary {
  background-color: #516b85b9;
  color: #FFF;
}

.globalMessage.accent {
  background-color: #0080ffb9;
  color: #FFF;
}

.globalMessage.warning {
  background-color: #fff3cdb9;
  color: #856404;
}

.globalMessage.warning a {
  color: #856404;
}

.primary .globalMessageClose img, .secondary .globalMessageClose img, .accent .globalMessageClose img {
  filter: brightness(0) invert(1);
}
